@mixin infoTile($width) {
  background: #1a1a1a;
  border: 0.5px solid #8f8f8f6e;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  border-radius: 25px;
  padding: 5px 20px 20px 30px;
  transition: all 0.2s ease-in-out;
  width: $width;
  height: 250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 640px) {
    height: 180px;
    width: 70vw;
  }
  .work_image {
    justify-content: center;
    img {
      justify-content: center;
      @media screen and (max-width: 640px) {
        height: 80px;
      }
    }
  }

  .title {
    margin-bottom: 0px;
    color: #ffff;
    h2 {
      font-size: 32px;
      font-weight: 500;
      font-family: "Montserrat";

      @media screen and (max-width: 640px) {
        font-size: 20px;
      }
    }
  }
  .content {
    font-size: 26px;
    font-weight: 400;
    font-family: "Montserrat";
    color: #ffff;
    @media screen and (max-width: 1024px) {
      margin-top: -15px;
    }
    @media screen and (max-width: 640px) {
      font-size: 10px;
    }
  }
}
.workPage {
  display: flex;
  flex-direction: column;

  .title {
    h1 {
      font-size: 32px;
      font-weight: 600;
      font-family: "Montserrat";
      color: #ffff;
    }
  }
  .workSection {
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    gap: 50px;
    .econommi {
      @include infoTile(85vw);
      cursor: pointer;
    }
    .econommi:hover {
      background-color: #3b3b3b;
    }
    .econommi:active {
      background-color: #3b3b3b;
      transform: scale(0.8);
      transition-duration: 0.5s;
    }
  }
}
.hive_image {
  img {
    height: 150px;
    @media screen and (max-width: 640px) {
      height: 50px;
    }
  }
}
.mcl_image {
  img {
    height: 150px;
    @media screen and (max-width: 640px) {
      height: 50px;
    }
  }
}
.econommi_image {
  img {
    @media screen and (max-width: 640px) {
      height: 20px;
      width: 90px;
    }
  }
}
.workPopUp {
  position: fixed;
  background: #0a0d14;
  color: #000;
  border: 0.5px solid #8f8f8f6e;
  height: 70vh;
  width: 60vw;
  border-radius: 15px;
  top: 8vw;
  right: 20%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    height: 40vh;
    top: 30vh;
  }

  @media screen and (max-width: 640px) {
    height: 30vh;
    top: 30vh;
  }
  button {
    font-size: 2vw;
    align-self: flex-end;
    color: #fff;
    background-color: unset;
    padding: 1.5vw;
    border: unset;
    cursor: pointer;
  }

  .popUp-content {
    display: flex;
    flex-direction: column;
    margin-top: -2vw;
    .popUp-firstRow {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      img {
        @media screen and (max-width: 640px) {
          height: 30px;
          width: 100px;
        }
      }
    }

    .introContent {
      color: #fff;
      font-family: "Montserrat";
      margin-top: -2vw;
      h3 {
        font-size: 2vw;
        margin-bottom: -1vw;
        font-weight: 700;
      }
      h4 {
        font-size: 1.5vw;
        margin-bottom: -1vw;
        font-weight: 400;
      }
      h5 {
        font-size: 1vw;
        margin-bottom: -1vw;
      }
    }
    .popUp-secondRow {
      margin-top: 5vw;
      ul {
        color: #fff;
        font-family: "Montserrat";
        font-size: 1.2vw;
        margin-right: 1vw;
        margin-left: 1vw;
        li {
          display: list-item;
          text-align: left;
        }
        a {
          text-align: left;
          margin-top: 20px;
        }
      }
    }
  }
}
:root {
  // background-color: #28364e;
  background-image: linear-gradient(#7338ac, #635ad9, #219be4, #88f4ff);
  // radial-gradient(#002070, #7254ab, #cf7cd7, #ea73db);
}

.active {
  filter: blur(20px);
  // pointer-events: none;
  user-select: none;
}
