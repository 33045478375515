.homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 100vh;
  // height: 100vh;
  .topButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .homeBtn {
      display: flex;
      justify-content: flex-start;
    }
  }
  .personalInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: "Montserrat";
    .picture {
      img {
        width: 20vw;
        height: auto;
        border-radius: 50vw;
        @media screen and (max-width: 1024px) {
          height: 25vh;
          width: 30vw;
        }
        @media screen and (max-width: 640px) {
          height: 30vh;
          width: 40vw;
        }
      }
    }
    .name {
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      font-family: "Montserrat";
      @media screen and (max-width: 1024px) {
        font-size: 32px;
      }
      @media screen and (max-width: 640px) {
        font-size: 24px;
      }
    }
    .tagline {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      color: #ffff;
      font-family: "Montserrat";
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
      @media screen and (max-width: 640px) {
        font-size: 12px;
      }
    }
  }
  .links {
    display: flex;
    flex-direction: row;
    gap: 40px;
    img {
      height: 50px;
    }
  }
  .linkedInLink {
    button {
      width: 40px;
      height: 30px;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
.swiper {
  width: 100vw;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-prev:after {
  color: #ffe3b3;
}
.swiper-button-next:after {
  color: #ffe3b3;
}
